'use strict';

$(function () {
  function init() {
    // SearchForm functionality
    initSearchForm(); // Subnavigation

    initNavigation(); // Liststyling

    initListStyling();
    initFlyToCart(); // Start the jQuery Plugins

    initPlugins();
    matchHeight();
  } // Add the arrow after the add to invoice buttons


  $('.addToInvoice').after('<span class="arrow arrow--right arrow--xsmall arrow___red"></span>');
  init();
});
$(window).on("load", function () {
  $(window).trigger('resize');
});
$(window).resize(function () {
  $('.owl-carousel').trigger('refresh.owl.carousel');
});
/**
 * Show / hide the searchform
 */

function initSearchForm() {
  var searchFormWrapper = $('#searchFormWrapper'); // var searchForm = $('#searchForm');
  // Toggle Searchform
  // if($(window).innerWidth() >= 992 ) {
  //     $('#toggleSearch').click(function(e){
  //         e.preventDefault();
  //
  //         if(searchFormWrapper.hasClass('is-active')) {
  //             console.log('send!');
  //             searchForm.submit();
  //         } else {
  //             searchFormWrapper.addClass('is-active');
  //             searchFormWrapper.find('input').focus();
  //         }
  //     });
  // }
  //Prevent form from closing on click
  // searchFormWrapper.click(function(e){
  //     e.stopPropagation();
  // });
  // // Close the form on click in window
  // $(window).click(function(){
  //     searchFormWrapper.removeClass('is-active');
  // });
}
/**
 * Handle the submenu on desktop and touch devices
 */


function initNavigation() {
  var navCans = $('#nav-cans');
  var hasSubnav = $('.has-subnav');
  var subMenuHolder = $('#subMenuHolder'); // if(navCans.find('> ul > li.active').length > 0) {
  //     subMenuHolder.addClass('active');
  // }

  $.each(hasSubnav, function () {
    if ($(this).hasClass('active')) {
      // $(this).find('.arrow').first().show();
      subMenuHolder.addClass('active');
    }
  });
  hasSubnav.hover(function () {
    $(this).addClass('open'); // $(this).find('.arrow').first().show();

    subMenuHolder.toggleClass('active');
  }, function () {
    $(this).removeClass('open');

    if (!$(this).hasClass('active')) {} // $(this).find('.arrow').first().hide();
    // Only hide the submenu if there is no parent


    if ($(this).parents('.has-subnav.open').length == 0 && navCans.find('> ul > li.active').length === 0) {
      subMenuHolder.removeClass('active');
    }
  });
  $.each(hasSubnav, function () {
    $(this).on('touchend', function (e) {
      e.preventDefault();
      e.stopPropagation();

      if ($(this).hasClass('open')) {
        window.location = $(this).find('a').attr('href');
      } else {
        // Close siblings
        closeSiblings($(this));
        $(this).addClass('open');
        $(this).find('.arrow').first().show();
        subMenuHolder.addClass('active');
      }
    });
  }); // Close the form on click in window
  // $(window).click(function(){
  //     hasSubnav.removeClass('open');
  //     $.each(hasSubnav, function(){
  //         if(!$(this).hasClass('active')) {
  //             $(this).find('.arrow').hide();
  //         }
  //     });
  //     if(navCans.find('> ul > li.active').length === 0) {
  //         subMenuHolder.removeClass('active');
  //     }
  // });
}

function initListStyling() {
  var lists = $('.content-block ul');
  $.each(lists, function () {
    $(this).addClass('list-default');
  });
}

function initFlyToCart() {
  $('.addToInvoice').on('click', function () {
    var cart = $('#offertemand');
    var imgtodrag = $(this).parents('.product').find("img").eq(0); // $(this).parents('.product').find("img").addClass("test");

    if (imgtodrag) {
      var imgclone = imgtodrag.clone().offset({
        top: imgtodrag.offset().top,
        left: imgtodrag.offset().left
      }).css({
        'opacity': '0.5',
        'position': 'absolute',
        'height': 'auto',
        'width': '150px',
        'z-index': '100'
      }).appendTo($('body')).animate({
        'top': cart.offset().top,
        'left': cart.offset().left,
        'width': 30,
        'height': 'auto'
      }, 1000, 'easeInOutExpo');
      setTimeout(function () {
        if (!cart.data("bouncing")) {
          cart.effect("bounce", {
            times: 3
          }, "slow").data("bouncing", true);
        }
      }, 1500);
      imgclone.animate({
        'width': 0,
        'height': 0
      }, function () {
        $(this).detach();
      });
      cart.data("bouncing", false);
    }
  });
}

function closeSiblings(menuItem) {
  var siblings = menuItem.siblings();
  siblings.removeClass('open');
  $.each(siblings, function () {
    if (!$(this).hasClass('active')) {
      $(this).find('.arrow').hide();
    }
  });
}

function initPlugins() {
  // jQuery MMenu
  $('#navCansOffcanvas').mmenu({// options
  }, {
    // configuration
    offCanvas: {
      pageSelector: '#site-wrapper'
    }
  });
  $('#mainMenu').mmenu({// options
  }, {
    // configuration
    offCanvas: {
      pageSelector: '#site-wrapper'
    }
  }); //Owl Carousel

  $('#homePageSlider').owlCarousel({
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 7000
  }); ////usps carousel

  var owlUsp = $("#owl-usp");
  owlUsp.owlCarousel({
    mouseDrag: false,
    touchDrag: true,
    slideSpeed: 800,
    paginationSpeed: 800,
    dots: false,
    nav: false,
    responsive: {
      0: {
        items: 1,
        autoWidth: false,
        autoplay: true,
        loop: true
      },
      768: {
        items: 3,
        autoWidth: true,
        margin: 10,
        autoplay: false
      }
    }
  }); ////recent products carousel

  var owlProducts = $("#owl-recent-products");
  owlProducts.owlCarousel({
    mouseDrag: false,
    touchDrag: true,
    slideSpeed: 800,
    loop: false,
    paginationSpeed: 800,
    dots: false,
    nav: false,
    responsive: {
      0: {
        items: 1,
        autoplay: true,
        stagePadding: 50
      },
      768: {
        items: 2,
        margin: 10,
        autoplay: false
      },
      992: {
        items: 4,
        margin: 10,
        autoplay: false
      }
    }
  }); ////team carousel

  var owlTeam = $("#owl-team");
  owlTeam.owlCarousel({
    mouseDrag: false,
    touchDrag: true,
    slideSpeed: 800,
    paginationSpeed: 800,
    dots: false,
    nav: false,
    responsive: {
      0: {
        items: 1,
        autoplay: true,
        loop: true,
        stagePadding: 40
      },
      768: {
        items: 2,
        margin: 10,
        autoplay: false
      },
      1024: {
        touchDrag: false,
        items: 2,
        margin: 10,
        autoplay: false
      },
      1600: {
        touchDrag: false,
        items: 3,
        margin: 10,
        autoplay: false
      }
    }
  }); // MatchHeight

  try {
    Typekit.load({
      active: function active() {
        $.fn.matchHeight._update();
      }
    });
  } catch (e) {} // Error
  // // Lightcase


  $('a[data-rel^=lightcase]').lightcase({
    transition: 'scrollHorizontal',
    showCaption: false
  }); //fit text inside div

  $(".categoryBoxWrapper .btn").fitText(2.0, {
    minFontSize: '11px',
    maxFontSize: '14px'
  });
} // for ajax/dynamic content 
// http://stackoverflow.com/questions/34393958/how-to-trigger-the-domnodeinserted-event-after-injecting-html-with-jquery/34398698#34398698


function loaded(selector, callback) {
  //trigger after page load.
  jQuery(function () {
    callback(jQuery(selector));
  });
  var parentSelector = ":has(" + selector + ")"; //trigger after page update eg ajax event or jquery insert.

  jQuery(document).on('DOMNodeInserted', parentSelector, function (e) {
    callback(jQuery(this).find(selector));
  });
}

function matchHeight() {
  $('.recent-product__description').matchHeight();
}